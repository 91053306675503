module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-catch-links@5.11.0_gatsby@5.13.3_@swc+core@1.4.1_@swc+helpers@0.5.2__babel-esli_kbyu7xlq5nv53mfh3nqz2wta2i/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-offline@6.11.0_gatsby@5.13.3_@swc+core@1.4.1_@swc+helpers@0.5.2__babel-eslint@1_ujjcg36mpejbqfp2wevuzruyea/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.3_@swc+core@1.4.1_@swc+helpers@0.5.2__babel-eslint@10.1.0_eslint@7.32.0__bufferut_iqjezojsrbyshvcji2afokv6je/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
